import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Error } from '@mui/icons-material'

const ContractorAlerts = ({ contractorData }) => {
    const { charges, invoices, jobs, payments } = contractorData

    const theme = useTheme()

    const pendingCharges = charges.filter((charge) => !charge.is_paid).length
    const unpaidInvoices = invoices.filter(
        (invoice) => invoice.invoice_status === 'unpaid'
    )
    const pastDueInvoices = invoices.filter(
        (invoice) =>
            invoice.invoice_status === 'unpaid' &&
            new Date(invoice.due_date) < new Date()
    ).length
    const cancelledInvoices = invoices.filter(
        (invoice) => invoice.invoice_status === 'cancelled'
    ).length
    const paidInvoices = invoices.filter(
        (invoice) => invoice.invoice_status === 'paid'
    ).length
    const totalPayments = payments.length

    const inProgressJobs = jobs.filter(
        (job) => job.job_status === 'In Progress'
    ).length
    const pendingJobs = jobs.filter(
        (job) => job.job_status === 'Pending'
    ).length
    const completedJobs = jobs.filter(
        (job) => job.job_status === 'Completed'
    ).length
    const overdueJobs = jobs.filter(
        (job) =>
            job.job_status === 'Pending' &&
            new Date(job.start_date) < new Date()
    ).length

    const getPendingChargesTotal = () => {
        return charges.reduce((acc, charge) => {
            if (!charge.has_been_invoiced) {
                return acc + charge.amount
            }
            return acc
        }, 0)
    }

    const getPaymentsTotal = () => {
        //the payment.payment_amount is the amount that was paid and its a string
        return payments.reduce((acc, payment) => {
            if (payment.payment_amount === null) {
                return acc
            }
            return (
                acc +
                parseFloat(
                    payment.payment_amount.replace('$', '').replace(',', '')
                )
            )
        }, 0)
    }

    return (
        <Box padding={1} width={'100%'} height={'100%'}>
            <Box
                backgroundColor={theme.palette.primary.supportingWhite}
                borderRadius={3}
                padding={1}
                width={'100%'}
                height={'100%'}
            >
                <Grid container padding={1} height={'100%'}>
                    <Grid item xs={6}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 'heavy',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Jobs
                        </Typography>

                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Pending: {pendingJobs}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            In Progress: {inProgressJobs}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Completed: {completedJobs}
                        </Typography>

                        <Box display={'flex'} flexDirection={'row'}>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {overdueJobs > 0 && (
                                    <Error
                                        sx={{
                                            fontSize: '1rem',
                                            color: theme.palette.primary
                                                .errorRed,
                                        }}
                                    />
                                )}
                            </Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 'light',
                                    color:
                                        overdueJobs > 0
                                            ? theme.palette.primary.errorRed
                                            : theme.palette.primary.textBlack,
                                }}
                            >
                                Late: {overdueJobs}
                            </Typography>
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Total: {jobs.length}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 'heavy',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Invoices
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Unpaid Invoices: {unpaidInvoices?.length}
                        </Typography>

                        <Box display={'flex'} flexDirection={'row'}>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {pastDueInvoices > 0 && (
                                    <Error
                                        sx={{
                                            fontSize: '1rem',
                                            color: theme.palette.primary
                                                .errorRed,
                                        }}
                                    />
                                )}
                            </Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 'light',
                                    color:
                                        pastDueInvoices > 0
                                            ? theme.palette.primary.errorRed
                                            : theme.palette.primary.textBlack,
                                }}
                            >
                                Past Due Invoices: {pastDueInvoices}
                            </Typography>
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Cancelled Invoices: {cancelledInvoices}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Paid Invoices: {paidInvoices}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 'heavy',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Charges
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Pending: {pendingCharges}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Pending Total:{' '}
                            {getPendingChargesTotal().toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 'heavy',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Payments
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Completed: {totalPayments}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textBlack,
                            }}
                        >
                            Total:{' '}
                            {getPaymentsTotal().toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ContractorAlerts
